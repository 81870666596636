<template>
    <footer class="footer">

        <div class="footer__container">

            <div class="footer__top">

                <div class="footer__col">

                    <img class="footer__logo" src="../assets/img/exalab-logo-blanco.svg" alt="">

                </div>

                <div class="footer__col">

                    <div class="footer__social social">

                        <ul class="social__ul">

                            <li class="social__li">
                                <a href="#" class="social__a">
                                    <i class="social__i ri-facebook-circle-line"></i>
                                </a>
                            </li>

                            <li class="social__li">
                                <a href="#" class="social__a">
                                    <i class="social__i ri-instagram-line"></i>
                                </a>
                            </li>

                            <li class="social__li">
                                <a href="#" class="social__a">
                                    <i class="social__i ri-twitter-x-line"></i>
                                </a>
                            </li>

                            <li class="social__li">
                                <a href="#" class="social__a">
                                    <i class="social__i ri-whatsapp-line"></i>
                                </a>
                            </li>

                        </ul>

                    </div>

                </div>

            </div>

            <div class="footer__main">

                <div class="footer__col">
                    <div class="footer__menu">
                        <h4 class="footer__menu--h4">
                            Menú
                        </h4>
                        <ul class="footer__menu--ul">
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Inicio
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Nosotros
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Productos
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Testimonios
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Contacto
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="footer__col">
                    <div class="footer__menu">
                        <h4 class="footer__menu--h4">
                            Exalab Core
                        </h4>
                        <ul class="footer__menu--ul">
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Agendamiento
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Atención Paciente
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Emisiones de Informes
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Operaciones
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Finanzas
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Gestión de Baterías
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="footer__col">
                    <div class="footer__menu">
                        <h4 class="footer__menu--h4">
                            Plugins
                        </h4>
                        <ul class="footer__menu--ul">
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Agendamiento pacientes particulares
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Facturación
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Firma Electrónica
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Checking
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Encuesta
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Bodegas de Insumos
                                </a>
                            </li>
                            <li class="footer__menu--li">
                                <a href="#" class="footer__menu--a">
                                    Integración IA
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="footer__col">
                    <div class="footer__menu">
                        <h4 class="footer__menu--h4">
                            Legal
                        </h4>
                        <ul class="footer__menu--ul">
                            <li v-for="p in policies" :key="p.id" class="footer__menu--li">
                                <RouterLink  class="footer__menu--a" :to="{name:'policy',params:{id:p.link_name}}">
                                   {{ p.name }}
                                </RouterLink>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <div class="footer__copy">

                <span class="footer__copy--span">
                    Copyright © 2024 Ingeniería en Informática MDTech Ltda. contacto@exalabcloud.cl
                </span>

            </div>

        </div>

    </footer>
</template>
<script lang="ts" setup>
import { onMounted, Ref, ref } from 'vue';
import LegalService from '@/services/LegalService';
import Policy from '@/entities/Policy';

const legalService: Ref<LegalService> = ref(new LegalService())
const policies: Ref<Policy[]> = ref<Policy[]>([])

onMounted(async () => {
    await getPolicy()
})

const getPolicy = async () => {
    policies.value = await legalService.value.getPolicies()
}

</script>