<template>
    <TopBar></TopBar>
        <main class="main">
            <HeroSection :policy="policy"></HeroSection>
            <BodySection :policy="policy"></BodySection>
        </main>
    <FooterBar />
</template>
<script lang="ts" setup>
import HeroSection from '@/components/legal/HeroSection.vue';
import TopBar from '../components/TopBar.vue'
import FooterBar from '@/components/FooterBar.vue';
import { onMounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import BodySection from '@/components/legal/BodySection.vue';
import LegalService from '@/services/LegalService';
import Policy from '@/entities/Policy';

const route = useRoute()
const legalService: Ref<LegalService> = ref(new LegalService())
const policy: Ref<Policy> = ref<Policy>({name:'',short_descript:'',descript:'', link_name:''})
const policyId: string = route.params.id.toString()

onMounted(async () => {
    await getPolicy()
})

const getPolicy = async () => {
    policy.value = await legalService.value.getPolicyById(policyId)
}

</script>