<template>
    <section class="services">

        <div class="services__container container">

            <div class="services__title">
                <img class="services__title--logo" src="assets/img/exalab-logo-blanco.svg" alt="">
                <p class="services__title--p">Somos un sistema especialiado en atención</p>
                <h2 class="services__title--h2">Preocupacional y Ocupacional</h2>
            </div>

            <div v-for="(modulo, index) in modulos" :key="index" class="services__box">
                <div class="services__image">
                    <img class="services__image--img" :src="'assets/img/services/' + modulo.image" alt="">
                </div>
                <div class="services__info">
                    <h3 class="services__info--h3">
                        <i :class="modulo.icon"></i>
                        <span>{{ modulo.name }}</span>
                    </h3>
                    <p class="services__info--p">
                        {{ modulo.short_descript }}
                    </p>
                    <RouterLink :to="{name:'services', params:{id:modulo.id}}" class="services__info--a services__button">
                        <span class="services__button--span">Ver más</span>
                        <i class="services__button--i ri-arrow-right-up-line"></i>
                    </RouterLink>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import Modulos from '@/entities/Modulos';
import ModulosService from '@/services/ModulosService';
import { onMounted, Ref, ref } from 'vue';

const modulosService: Ref<ModulosService> = ref(new ModulosService())
const modulos: Ref<Modulos[]> = ref([])

onMounted(async () => {
    getModulos()
})

const getModulos = async () => {
    modulos.value = await modulosService.value.getModulos()
}

</script>