<template>
      <section class="ruta">

<div class="ruta__container container">

    <div class="ruta__title">

        <h2 class="ruta__title--h2">Ruta de Implementación</h2>
        <p class="ruta__title--p">Tiempo estimado para la puesta en marcha del sistema</p>

    </div>

    <div class="ruta__content">

        <div class="ruta__items">

            <div class="ruta__header">
                <div class="ruta__header--span">Tiempo en Días</div>
            </div>

            <div class="ruta__body">

                <div class="ruta__body--item">
                    <div class="ruta__body--span">Primer Contacto</div>
                    <i class="ruta__body--i ri-phone-fill"></i>
                </div>

                <div class="ruta__body--item">
                    <div class="ruta__body--span">Se presenta la Demo</div>
                    <i class="ruta__body--i ri-pages-line"></i>
                </div>

                <div class="ruta__body--item">
                    <div class="ruta__body--span">Se envía la cotización</div>
                    <i class="ruta__body--i ri-file-paper-2-line"></i>
                </div>

                <div class="ruta__body--item">
                    <div class="ruta__body--span">Primer Pago</div>
                    <i class="ruta__body--i ri-bank-card-2-line"></i>
                </div>

                <div class="ruta__body--item">
                    <div class="ruta__body--span"><span>(Blueprint)</span> Solicitud <span>de</span> datos <span>de la</span> empresa</div>
                    <i class="ruta__body--i ri-survey-line"></i>
                </div>

                <div class="ruta__body--item">
                    <div class="ruta__body--span">Se levanta el ambiente de la emrpesa</div>
                    <i class="ruta__body--i  ri-terminal-line"></i>
                </div>

                <div class="ruta__body--item">
                    <div class="ruta__body--span">Comienzan las Capactiaciones</div>
                    <i class="ruta__body--i ri-presentation-fill"></i>
                </div>

                <div class="ruta__body--item">
                    <div class="ruta__body--span">Parametrización del Sistema</div>
                    <i class="ruta__body--i  ri-tools-line"></i>
                </div>

                <div class="ruta__body--item">
                    <div class="ruta__body--span">Marcha Blanca 2 Semanas</div>
                    <i class="ruta__body--i ri-flag-2-line"></i>
                </div>

                <div class="ruta__body--item">
                    <div class="ruta__body--span">Regimen Normal de Operación</div>
                    <i class="ruta__body--i ri-flag-2-fill"></i>
                </div>

            </div>
        </div>

        <div class="ruta__days">

            <div class="ruta__header">
                <div class="ruta__header--day">1</div>
                <div class="ruta__header--day">2</div>
                <div class="ruta__header--day">3</div>
                <div class="ruta__header--day">4</div>
                <div class="ruta__header--day">5</div>
                <div class="ruta__header--day">6</div>
                <div class="ruta__header--day">7</div>
                <div class="ruta__header--day">8</div>
                <div class="ruta__header--day">9</div>
                <div class="ruta__header--day">10</div>
                <div class="ruta__header--day">11</div>
                <div class="ruta__header--day">12</div>
                <div class="ruta__header--day">13</div>
                <div class="ruta__header--day">14</div>
                <div class="ruta__header--day">15</div>
                <div class="ruta__header--day">16</div>
                <div class="ruta__header--day">17</div>
                <div class="ruta__header--day">18</div>
                <div class="ruta__header--day">19</div>
                <div class="ruta__header--day">20</div>
                <div class="ruta__header--day">21</div>
                <div class="ruta__header--day">22</div>
                <div class="ruta__header--day">23</div>
                <div class="ruta__header--day">24</div>
                <div class="ruta__header--day">25</div>
                <div class="ruta__header--day">26</div>
                <div class="ruta__header--day">27</div>
                <div class="ruta__header--day">28</div>
                <div class="ruta__header--day">29</div>
                <div class="ruta__header--day">30</div>
                <div class="ruta__header--day">31</div>
                <div class="ruta__header--day">32</div>
                <div class="ruta__header--day">33</div>
                <div class="ruta__header--day">34</div>
                <div class="ruta__header--day">35</div>
            </div>

            <div class="ruta__charts">
                <div class="ruta__charts--1"></div>
                <div class="ruta__charts--2"></div>
                <div class="ruta__charts--3"></div>
                <div class="ruta__charts--4"></div>
                <div class="ruta__charts--5"></div>
                <div class="ruta__charts--6"></div>
                <div class="ruta__charts--7"></div>
                <div class="ruta__charts--8"></div>
                <div class="ruta__charts--9"></div>
                <div class="ruta__charts--10"></div>
            </div>


        </div>
        
    </div>

    <div class="ruta__additional">

        <div class="ruta__colors">
            <div class="ruta__color">
                <div class="ruta__color--color"></div>
                <span class="ruta__color--span">Tiempos Verdes Dependen de Exalab</span>
            </div>

            <div class="ruta__color">
                <div class="ruta__color--color"></div>
                <span class="ruta__color--span">Tiempos Azules Dependen del Cliente</span>
            </div>
        </div>

        <div class="ruta__explanation">
            <span class="ruta__explanation--span">
                Los tiempos planteados en esta ruta son estimativos sin contemplar la activación de plugins.
            </span>
        </div>

    </div>
    
</div>

</section>
</template>