<template>
      <section class="faq">
            <div class="faq__container container">
                <div class="faq__title">
                    <h2 class="faq__title--h2">Preguntas Frecuentes</h2>
                    <p class="faq__title--p">Algunas de las dudas más comunes de los usuarios</p>
                </div>

                <div class="faq__content">

                    <div class="accordion">

                        <div v-for="(faq,index) in faqs" :key="index" class="accordion__item">

                            <div class="accordion__item--header">
                                <i class="accordion__item--i ri-arrow-right-s-line"></i>
                                <h4 class="accordion__item--h4">{{ faq.name }}</h4>
                            </div>

                            <div class="accordion__item--body">
                                <p class="accordion__item--p">
                                    {{ faq.descript }}
                                </p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
</template>
<script lang="ts" setup>
import { onMounted, ref, Ref } from 'vue';
import Faq from "@/entities/Faq"
import FaqService from "@/services/FaqService"

const faqService = ref(new FaqService())
const faqs: Ref<Faq[]> = ref([])

onMounted(async () => {
    await getFaqs()
    accordion()
})

const getFaqs = async () => {
    faqs.value = await faqService.value.getFaqs()
}

type InitialState = "todos-cerrados" | "todos-abiertos" | "primero-abierto";
type MultiOpen = "solo-1" | "multi";

const accordion = (initialState: InitialState = "todos-cerrados", multiOpen: MultiOpen = "solo-1"): void => {
    const accordions = document.querySelectorAll<HTMLDivElement>(".accordion");

    accordions.forEach((accordion) => {
        const items = accordion.querySelectorAll<HTMLDivElement>(".accordion__item");

        items.forEach((item, idx) => {
            const itemHeader = item.querySelector<HTMLElement>(".accordion__item--header");
            const itemBody = item.querySelector<HTMLElement>(".accordion__item--body");
            const itemIcon = item.querySelector<HTMLElement>(".accordion__item--i");

            if (!itemHeader || !itemBody || !itemIcon) return; // Asegurarse de que los elementos existen

            // Inicializar acordeones según el estado inicial
            const originalBodyHeight = itemBody.scrollHeight;
            itemBody.style.transition = 'none'; // Desactivar transición para la inicialización

            if (initialState === "todos-abiertos") {
                itemBody.style.height = `${originalBodyHeight}px`;
                itemBody.style.paddingTop = '1rem';
                itemBody.style.paddingBottom = '1rem';
                itemIcon.classList.add('rotate');
            } else if (initialState === "todos-cerrados") {
                itemBody.style.height = '0';
                itemBody.style.paddingTop = '0';
                itemBody.style.paddingBottom = '0';
                itemBody.style.overflow = 'hidden';
            } else if (initialState === "primero-abierto" && idx === 0) {
                itemBody.style.height = `${originalBodyHeight}px`;
                itemBody.style.paddingTop = '1rem';
                itemBody.style.paddingBottom = '1rem';
                itemIcon.classList.add('rotate');
            } else {
                itemBody.style.height = '0';
                itemBody.style.paddingTop = '0';
                itemBody.style.paddingBottom = '0';
                itemBody.style.overflow = 'hidden';
            }

            itemBody.offsetHeight; // Forzar reflujo
            itemBody.style.transition = 'height 0.5s ease-in-out, padding-top 0.5s ease-in-out, padding-bottom 0.5s ease-in-out'; // Reactivar transición

            itemHeader.addEventListener("click", () => {
                if (itemBody.offsetHeight > 0) {
                    // Si itemBody está abierto, cerrarlo
                    itemBody.style.height = `${itemBody.offsetHeight}px`; // Establecer altura actual sin transición
                    itemBody.offsetHeight; // Forzar reflujo
                    itemBody.style.height = '0';
                    itemBody.style.paddingTop = '0';
                    itemBody.style.paddingBottom = '0';
                    itemBody.style.overflow = 'hidden';

                    // Rotar el ícono de vuelta
                    itemIcon.classList.remove('rotate');
                } else {
                    // Si solo puede abrirse un item a la vez
                    if (multiOpen === "solo-1") {
                        items.forEach((otherItem) => {
                            if (otherItem !== item) {
                                const otherBody = otherItem.querySelector<HTMLElement>(".accordion__item--body");
                                const otherIcon = otherItem.querySelector<HTMLElement>(".accordion__item--i");
                                if (otherBody && otherIcon) {
                                    otherBody.style.height = '0';
                                    otherBody.style.paddingTop = '0';
                                    otherBody.style.paddingBottom = '0';
                                    otherBody.style.overflow = 'hidden';
                                    otherIcon.classList.remove('rotate');
                                }
                            }
                        });
                    }

                    // Si itemBody está cerrado, abrirlo
                    itemBody.style.height = '0'; // Establecer altura inicial a 0
                    itemBody.style.paddingTop = '0'; // Establecer padding inicial a 0
                    itemBody.style.paddingBottom = '0'; // Establecer padding inicial a 0
                    itemBody.offsetHeight; // Forzar reflujo
                    itemBody.style.height = `${originalBodyHeight}px`;
                    itemBody.style.paddingTop = '1rem';
                    itemBody.style.paddingBottom = '1rem';
                    itemBody.style.overflow = 'hidden';

                    // Rotar el ícono
                    itemIcon.classList.add('rotate');
                }
            });
        });
    });
};
</script>