<template>
    <section class="specialized">

        <div class="specialized__container container">

            <div class="specialized__title">

                <h2 class="specialized__title--h2">
                    Servicios Especializados
                </h2>

                <p class="specialized__title--p">
                    soluciones a medida para tu clínica
                </p>

            </div>

            <div class="specialized__content">

                <div class="specialized__info">

                    <div class="specialized__row">

                        <div  class="specialized__card">

                            <div class="specialized__card--info">
                                <i class="specialized__card--i ri-bubble-chart-line"></i>
                                <h4 class="specialized__card--h4">
                                    Analítica de datos Bi
                                </h4>
                                <p class="specialized__card--p">
                                    Implementación de inteligencia de negocios para transformar 
                                    datos en insights estratégicos mediante reportes y dashboards interactivos.
                                </p>
                            </div>

                            <div class="specialized__card--cta">
                                <a class="specialized__card--a" href="">
                                    Ver más
                                    <i class="ri-arrow-right-up-line"></i>
                                </a>
                            </div>

                        </div>

                        <div class="specialized__card">

                            <div class="specialized__card--info">
                                <i class="specialized__card--i ri-terminal-line"></i>
                                <h4 class="specialized__card--h4">
                                    Desarrollo a medida
                                </h4>
                                <p class="specialized__card--p">
                                    Creación de funcionalidades personalizadas para adaptarse a necesidades específicas
                                     del centro médico, mejorando la eficiencia operativa.
                                </p>
                            </div>

                            <div class="specialized__card--cta">
                                <a class="specialized__card--a" href="">
                                    Ver más
                                    <i class="ri-arrow-right-up-line"></i>
                                </a>
                            </div>

                        </div>

                    </div>

                    <div class="specialized__row">

                        <div class="specialized__card">

                            <div class="specialized__card--info">
                                <i class="specialized__card--i ri-presentation-fill"></i>
                                <h4 class="specialized__card--h4">
                                    Recapacitación
                                </h4>
                                <p class="specialized__card--p">
                                    Capacitación continua del personal en el uso de Exalab para maximizar
                                     el aprovechamiento de las funcionalidades del sistema.
                                </p>
                            </div>

                            <div class="specialized__card--cta">
                                <a class="specialized__card--a" href="">
                                    <span>Ver más</span>
                                    <i class="ri-arrow-right-up-line"></i>
                                </a>
                            </div>

                        </div>

                        <div class="specialized__card">

                            <div class="specialized__card--info">
                                <i class="specialized__card--i ri-flow-chart"></i>
                                <h4 class="specialized__card--h4">
                                    Integraciones
                                </h4>
                                <p class="specialized__card--p">
                                    Conexión fluida de Exalab con otros sistemas y herramientas, mejorando la 
                                    interoperabilidad y el flujo de información.
                                </p>
                            </div>

                            <div class="specialized__card--cta">
                                <a class="specialized__card--a" href="">
                                    Ver más
                                    <i class="ri-arrow-right-up-line"></i>
                                </a>
                            </div>

                        </div>

                    </div>

                </div>

                <div class="specialized__featured">

                    <img class="specialized__featured--img" :src="'assets/img/specialized/analitica-bi.jpeg'" alt="">

                </div>

            </div>

            <div class="specialized__mobile">

                <div class="specialized__mobile--feature">
                    <img src="assets/img/specialized/1.jpg" class="specialized__mobile--img">
                </div>

                <div class="specialized__mobile--info">
                    <div class="specialized__mobile--card">
                        <i class="specialized__mobile--i ri-bubble-chart-line"></i>
                        <h4 class="specialized__mobile--h4">
                            Analítica de datos Bi
                        </h4>
                        <p class="specialized__mobile--p">
                            sim, laoreet a nunc. In eleifend eros felis, ut laoreet nulla egestas a. In turpis enim, lac
                        </p>
                        <a href="#" class="specialized__mobile--a">
                            Ver más
                            <i class="ri-arrow-right-up-line"></i>
                        </a>

                        <div class="specialized__mobile--left">
                            <i class="ri-arrow-left-s-line"></i>
                        </div>

                        <div class="specialized__mobile--right">
                            <i class="ri-arrow-right-s-line"></i>
                        </div>

                    </div>
                </div>

            </div>

            

        </div>

    </section>
</template>
<script lang="ts" setup>
import {ref} from "vue"
let specClass = ref<string[]>(['specialized__card'])
const addActive = () => {
  if (!specClass.value.includes('active')) {
  specClass.value.push('active');
  }
};

const removeActive = () => {
  const index = specClass.value.indexOf('active');
  if (index !== -1) {
    specClass.value.splice(index, 1);
  }
};


</script>