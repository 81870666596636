import Plans from "@/entities/Plans"
import { supabase } from "@/lib/supabaseClient"

export default class PlansService {

    public async getPlans(): Promise<Plans[]> {
        const { data, error } = await supabase.from('plans').select().order('price', { ascending: true })
        if (error) throw error
        return data as Plans[]
    }

}