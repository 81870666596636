import Modulos from "@/entities/Modulos"
import ModuloDetail from "@/entities/ModulosDetails"
import { supabase } from "@/lib/supabaseClient"

export default class ModulosService {

    public async getModulos(): Promise<Modulos[]> {
        const { data, error } = await supabase.from('modulos').select().order('index', { ascending: true })
        if (error) throw error
        return data as Modulos[]
    }

    public async getModuloById(id:number): Promise<Modulos> {
        const { data, error } = await supabase.from('modulos').select().eq('id',id).single()
        if (error) throw error
        return data as Modulos
    }

    public async getDetalleModuloById(id:number): Promise<ModuloDetail[]> {
        const { data, error } = await supabase.from('modulos_details').select().eq('modulo_id',id).order('index')
        if (error) throw error
        return data as ModuloDetail[]
    }

}