import Policy from "@/entities/Policy"
import { supabase } from "@/lib/supabaseClient"

export default class LegalService{

    public async getPolicies(): Promise<Policy[]> {
        const { data, error } = await supabase.from('policy').select().order('id', { ascending: true })
        if (error) throw error
        return data as Policy[]
    }

    public async getPolicyById(id:string): Promise<Policy> {
        const { data, error } = await supabase.from('policy').select().eq('link_name',id).single()
        if (error) throw error
        return data as Policy
    }
}