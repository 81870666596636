import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PriceView from '@/views/PriceView.vue'
import ServicesView from '@/views/ServicesView.vue'
import LegalView from '@/views/LegalView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/price',
    name: 'price',
    component: PriceView
  },
  {
    path: '/services/:id',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/policy/:id',
    name: 'policy',
    component: LegalView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
