<template>
    <section class="testimonials">
        <div class="testimonials__container container">

            <div class="testimonials__title">
                <h2 class="testimonials__title--h2">
                    Testimonios
                </h2>
                <p class="testimonials__title--p">
                    lo que nuestros clientes dicen de nosotros
                </p>
            </div>

            <div class="testimonials__list">
                <div v-for="(item, index) in testimonials" :key="index">
                    <div v-if="item.type == 1" class="testimonials__item testimonials__item--text">

                        <div class="testimonials__person">

                            <div class="testimonials__person--picture">
                                <img class="testimonials__person--img"
                                    :src="'assets/img/testimonials/' + item.user_avatar" alt="">
                            </div>

                            <div class="testimonials__person--info">
                                <h4 class="testimonials__person--h4">{{ item.name }}</h4>
                                <p class="testimonials__person--p">{{ item.position }}</p>
                            </div>

                        </div>
                        <div class="testimonials__content">
                            <p class="testimonials__content--p">
                                {{ item.comment }}
                            </p>
                        </div>

                    </div>


                    <div v-if="item.type == 2" class="testimonials__item testimonials__item--video">

                        <div class="testimonials__person">

                            <div class="testimonials__person--picture">
                                <img class="testimonials__person--img"
                                    :src="'assets/img/testimonials/' + item.user_avatar" alt="">
                            </div>

                            <div class="testimonials__person--info">
                                <h4 class="testimonials__person--h4">{{ item.name }}</h4>
                                <p class="testimonials__person--p">{{ item.position }}</p>
                            </div>

                        </div>
                        <div class="testimonials__content">
                            <iframe class="testimonials__content--iframe iframe-3-4" :src="item.video_url?.toString()"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                            </iframe>
                        </div>

                    </div>

                    <div v-if="item.type == 3"
                        class="testimonials__item testimonials__item--video testimonial__video--9-16">

                        <div class="testimonials__person">

                            <div class="testimonials__person--picture">
                                <img class="testimonials__person--img"
                                    :src="'assets/img/testimonials/' + item.user_avatar" alt="">
                            </div>

                            <div class="testimonials__person--info">
                                <h4 class="testimonials__person--h4">{{ item.name }}</h4>
                                <p class="testimonials__person--p">{{ item.position }}</p>
                            </div>

                        </div>
                        <div class="testimonials__content">
                            <iframe class="testimonials__content--iframe iframe-9-16" :src="item.video_url?.toString()"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                            </iframe>
                        </div>

                    </div>

                    <div v-if="item.type == 4" class="testimonials__item testimonials__item--audio">

                        <div class="testimonials__person">

                            <div class="testimonials__person--picture">
                                <img class="testimonials__person--img"
                                    :src="'assets/img/testimonials/' + item.user_avatar" alt="">
                            </div>

                            <div class="testimonials__person--info">
                                <h4 class="testimonials__person--h4">{{ item.name }}</h4>
                                <p class="testimonials__person--p">{{ item.position }}</p>
                            </div>

                        </div>
                        <div class="testimonials__content">

                            <div class="audio-player">
                                <div class="audio-player__play-pause-button">
                                    <i class="ri-play-large-fill"></i>
                                </div>
                                <div class="audio-player__progress-bar">
                                    <div class="audio-player__progress"></div>
                                </div>
                                <audio class="audio-player__audio" :src="'assets/audios/' + item.audio_file"></audio>
                            </div>

                        </div>

                    </div>

                    <div v-if="item.type == 5" class="testimonials__item testimonials__item--img">
                        <div class="testimonials__person">

                            <div class="testimonials__person--picture">
                                <img class="testimonials__person--img"
                                    :src="'assets/img/testimonials/' + item.user_avatar" alt="">
                            </div>

                            <div class="testimonials__person--info">
                                <h4 class="testimonials__person--h4">{{ item.name }}</h4>
                                <p class="testimonials__person--p">{{ item.position }}</p>
                            </div>

                        </div>
                        <img class="testimonials__img" :src="'assets/img/testimonials/' + item.image_src" alt="">
                    </div>

                </div>

            </div>

            <button id="toggleButton" class="testimonials__button">Ver más</button>
        </div>

    </section>
</template>

<script lang="ts" setup>
import Testimonials from '@/entities/Testimonials';
import TestimonialsService from '@/services/TestimonialsService';
import { onMounted, ref, Ref } from 'vue';

const testimonialsService: Ref<TestimonialsService> = ref(new TestimonialsService())
const testimonials: Ref<Testimonials[]> = ref([])

onMounted(async () => {
    await getTestimonials()
    initializeAudioPlayers()
})

const getTestimonials = async () => {
    testimonials.value = await testimonialsService.value.getTestimonials()
}

const initializeAudioPlayers= (): void => {
    document.querySelectorAll<HTMLElement>('.audio-player').forEach((player) => {
        const audio = player.querySelector<HTMLAudioElement>('.audio-player__audio');
        const playPauseButton = player.querySelector<HTMLButtonElement>('.audio-player__play-pause-button');
        const progressBar = player.querySelector<HTMLElement>('.audio-player__progress-bar');
        const progress = player.querySelector<HTMLElement>('.audio-player__progress');

        if (!audio || !playPauseButton || !progressBar || !progress) {
            return; // Asegurarse de que todos los elementos existen antes de proceder
        }

        playPauseButton.addEventListener('click', () => {
            if (audio.paused) {
                // Pausar todos los otros reproductores de audio
                document.querySelectorAll<HTMLAudioElement>('.audio-player__audio').forEach((otherAudio) => {
                    if (otherAudio !== audio) {
                        otherAudio.pause();
                        const otherButton = otherAudio.closest('.audio-player')?.querySelector<HTMLButtonElement>('.audio-player__play-pause-button');
                        if (otherButton) {
                            otherButton.innerHTML = '<i class="ri-play-large-fill"></i>';
                        }
                    }
                });

                audio.play();
                playPauseButton.innerHTML = '<i class="ri-pause-large-fill"></i>';
            } else {
                audio.pause();
                playPauseButton.innerHTML = '<i class="ri-play-large-fill"></i>';
            }
        });

        audio.addEventListener('timeupdate', () => {
            const progressPercent = (audio.currentTime / audio.duration) * 100;
            progress.style.width = `${progressPercent}%`;
        });

        audio.addEventListener('ended', () => {
            playPauseButton.innerHTML = '<i class="ri-play-large-fill"></i>';
            progress.style.width = '0%';
        });
    });
}

</script>