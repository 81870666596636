<template>
    <section class="security">

        <div class="security__container container">

            <div class="security__title">

                <h2 class="security__title--h2">
                    Infraestructura y Seguridad
                </h2>

                <p class="security__title--p">
                    trabajamos con las mejores prácticas
                </p>

            </div>


            <div class="security__content">

                <div class="security__item">

                    <div class="security__icon">
                        <i class="security__icon--i ri-customer-service-line"></i>
                    </div>

                    <div class="security__info">
                        <p class="security__info--p">
                            Soporte técnico especializado
                        </p>
                    </div>

                </div>

                <div class="security__item">

                    <div class="security__icon">
                        <i class="security__icon--i ri-alert-line"></i>
                    </div>

                    <div class="security__info">
                        <p class="security__info--p">
                            Protocolos de emergencias
                        </p>
                    </div>

                </div>

                <div class="security__item">

                    <div class="security__icon">
                        <i class="security__icon--i ri-archive-drawer-line"></i>
                    </div>

                    <div class="security__info">
                        <p class="security__info--p">
                            Servicios 24/7 todo el año
                        </p>
                    </div>

                </div>

                <div class="security__item">

                    <div class="security__icon">
                        <i class="security__icon--i ri-file-copy-line"></i>
                    </div>

                    <div class="security__info">
                        <p class="security__info--p">
                            Respaldos automatizados de información
                        </p>
                    </div>

                </div>

                <div class="security__item">

                    <div class="security__icon">
                        <i class="security__icon--i ri-timer-line"></i>
                    </div>

                    <div class="security__info">
                        <p class="security__info--p">
                            Rápidos tiempos de respuestas estipulados por contrato
                        </p>
                    </div>

                </div>

                <div class="security__item">

                    <div class="security__icon">
                        <i class="security__icon--i ri-award-line"></i>
                    </div>

                    <div class="security__info">
                        <p class="security__info--p">
                            Más de 1 decada de experiencia nos avala
                        </p>
                    </div>

                </div>

                <div class="security__item">

                    <div class="security__icon">
                        <i class="security__icon--i ri-lock-star-line"></i>
                    </div>

                    <div class="security__info">
                        <p class="security__info--p">
                            Enfasis en calidad y seguridad
                        </p>
                    </div>

                </div>

                <div class="security__item">

                    <div class="security__icon">
                        <i class="security__icon--i ri-git-pull-request-line"></i>
                    </div>

                    <div class="security__info">
                        <p class="security__info--p">
                            Innovación, tecnología de vanguardia
                        </p>
                    </div>

                </div>

            </div>

        </div>

    </section>
</template>