import Plugins from "@/entities/Plugins"
import { supabase } from "@/lib/supabaseClient"

export default class PluginsService {

    public async getPlugins(): Promise<Plugins[]> {
        const { data, error } = await supabase.from('plugins').select().order('index', { ascending: true })
        if (error) throw error

        return data as Plugins[]
    }

}