<template>
      <div v-for="detail in modulos" :key="detail.id" :class="['mod-featured', detail.mirror?'mirror':'']">
            <div class="mod-featured__img entry2">
                <img class="mod-featured__img--img" :src="'/assets/img/services/details/' + detail.image">
            </div>
            <div class="mod-featured__info">
                <span class="mod-featured__info--span">
                    Característica
                </span>
                <h2 class="mod-featured__info--h2">
                    {{ detail.title }}
                </h2>
                <p class="mod-featured__info--p">
                    {{ detail.descript }}
                </p>
            </div>
        </div>

</template>

<script lang="ts" setup>
import ModulosService from '@/services/ModulosService';
import { onMounted, defineProps, Ref, ref, watch } from 'vue';
import ModuloDetails from '@/entities/Modulos'

const modulosService: Ref<ModulosService> = ref(new ModulosService())
const modulos:Ref<ModuloDetails> = ref([])
const props = defineProps<{
      id: number
}>();

onMounted(async () => {
    modulos.value = await modulosService.value.getDetalleModuloById(props.id)
})

watch(() => props.id, async (newId) => {
    modulos.value = await modulosService.value.getDetalleModuloById(newId)
});

</script>