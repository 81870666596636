<template>
    <section class="calculator">
            <div class="calculator__container container">
                <div class="calculator__title">
                    <h2 class="calculator__title--h2">Calculadora de Costos</h2>
                    <p class="calculator__title--p">Calcula los costos unitarios por batería asociado a la contratación de Exalab</p>
                </div>
                <form>
                    <div class="calculator__content">
                    <div class="calculator__in">
                        <div class="calculator__field">
                            <label class="calculator__field--label">
                                Seleccione un Plan
                            </label>
                            <div class="calculator__field--in">
                                <div class="calculator__field--icon">
                                    <img src="assets/img/isotipo-blanco-exalab.svg" alt="">
                                </div>
                                <select class="calculator__field--select" v-model="selectedPlan">
                                    <option v-for="plan in planes" :key="plan.id" :value="plan">{{ plan.name }} ({{ plan.price }}UF - {{ plan.limit }} inf/mes)</option>
                                </select>
                            </div>
                        </div>
                        <div class="calculator__field">
                            <label class="calculator__field--label">
                                ¿Cuántos informes emites aproximadamente al mes?
                            </label>
                            <div class="calculator__field--in">
                                <div class="calculator__field--icon">
                                    <i class="ri-article-line"></i>
                                </div>
                                <input type="text" maxlength="6" @input="filterInputTotal" placeholder="Ingrese solo números" class="calculator__field--input" v-model="informesMensuales">
                            </div>
                        </div>
                        <div class="calculator__field">
                            <label class="calculator__field--label">
                                ¿En promedio, cuanto cobra por cada batería de exámenes?
                            </label>
                            <div class="calculator__field--in">
                                <div class="calculator__field--icon">
                                    <i class="ri-money-dollar-circle-line"></i>
                                </div>
                                <input type="text" maxlength="6" @input="filterInputValor" placeholder="Ingrese solo números" class="calculator__field--input" v-model="valorInformePromedio">
                            </div>
                        </div>

                        <div class="calculator__field">
                            <button class="calculator__field--submit">Calcular</button>
                        </div>
                        

                    </div>

                    <div class="calculator__out">

                        <div class="calculator__out--header">
                            <div class="calculator__out--h4">Resultados</div>
                            
                            <div class="calculator__out--type">
                                
                                <p class="calculator__out--m">
                                    Pago Anual <small>({{selectedPlan.discount}}% desc)</small> 
                                </p>
                                <div :class="['switch', pagoAnual?'':'b']" @click="switsh">
                                    <div class="switch__button"></div>
                                </div>
                                <p class="calculator__out--u">
                                    Pago Mensual
                                </p>
                            </div>
                        </div>

                        <div class="calculator__out--body">
                            <p class="calculator__out--p">
                                Tu costo promedio por informe emitido con el plan <strong>{{ selectedPlan.name }}</strong> sería <strong>${{ valorUnitarioInforme }}</strong> cada uno.
                            </p>
                            <p class="calculator__out--p">
                                Este costo equivale a un <strong>{{percentUnitarioInforme.toFixed(1)}}%</strong> del valor promedio de tus informes vendidos.
                            </p>
                            <p class="calculator__out--p">
                                <small>
                                    *Valor UF al día de hoy: <strong>${{ valorUf }}</strong>
                                </small>
                            </p>
                        </div>

                        <div class="calculator__out--chart">

                            <div class="calculator__out--data">
                                <div class="calculator__out--vi">
                                    <p class="calculator__out--vip">Valor Informe</p>
                                </div>
                                <div class="calculator__out--vichart">
                                    <div class="calculator__out--vispan">
                                        ${{ valorInformePromedio }} (unitario)
                                    </div>
                                </div>
                            </div>

                            <div class="calculator__out--data">
                                <div class="calculator__out--ce">
                                    <p class="calculator__out--cep">Costo Exalab</p>
                                </div>
                                <div class="calculator__out--vichart">
                                    <div class="calculator__out--vispan">
                                        ${{ valorUnitarioInforme }} (unitario)
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                </form>
               

            </div>
    </section>
</template>

<script lang="ts" setup>
import { onMounted, ref, Ref, computed } from 'vue'
import axios from 'axios'
import Plans from '@/entities/Plans';
import PlansService from '@/services/PlansService';

const planService: Ref<PlansService> = ref(new PlansService())
const planes: Ref<Plans[]> = ref([])
const valorUf = ref(0)
let informesMensuales = ref(1000)
let valorInformePromedio = ref(15000)
let selectedPlan:Ref<Plans> = ref({name:'Plan 1',price:0, limit:100,discount:8})
let pagoAnual = ref(true)

onMounted(async () => {
    valorUf.value = await getUFValue()
    await getPlanes()
    selectedPlan.value = planes.value[0]
})

const getUFValue = async(): Promise<number> => {
  try {
    const response = await axios.get('https://mindicador.cl/api');
    
    if (response.data && response.data.uf && response.data.uf.valor) {
      const ufValue: number = response.data.uf.valor;
      return ufValue;
    } else {
      return 37813.98;
    }
  } catch (error) {
    return 37813.98; //última verificación
  }
}

const getPlanes = async () => {
    planes.value = await planService.value.getPlans()
}

const filterInputTotal = () => {
    informesMensuales.value = informesMensuales.value.replace(/[^0-9]/g, '')
    if(informesMensuales.value>selectedPlan.value.limit)
        informesMensuales.value=selectedPlan.value.limit
}
const filterInputValor = () => {
    valorInformePromedio.value = valorInformePromedio.value.replace(/[^0-9]/g, '')
}

const valorUnitarioInforme = computed(():number => {
    let precio = (selectedPlan.value.price * valorUf.value)/informesMensuales.value
    if(!pagoAnual.value){
        precio = parseInt(precio.toString())
    }else{
        precio = parseInt((precio*(100-selectedPlan.value.discount)/100).toString())
    }
    return precio
})

const percentUnitarioInforme = computed(():number => {
  return (valorUnitarioInforme.value*100)/valorInformePromedio.value
})

const switsh = ():void => {
   pagoAnual.value=!pagoAnual.value
}

</script>