<template>
    <TopBar></TopBar>
    <main class="main">
      <PlansSection />
      <PluginsSection />
      <CalculatorSection />
      <ImplementsSection />
      <FaqSection />
      <ContactSection />
    </main>
    <FooterBar />
  </template>

<script lang="ts" setup>
import TopBar from '../components/TopBar.vue'
import FooterBar from '@/components/FooterBar.vue';
import ContactSection from '@/components/price/ContactSection.vue';
import CalculatorSection from '@/components/price/CalculatorSection.vue';
import FaqSection from '@/components/price/FaqSection.vue';
import ImplementsSection from '@/components/price/ImplementsSection.vue';
import PlansSection from '@/components/price/PlansSection.vue';
import PluginsSection from '@/components/price/PricePluginsSection.vue';
import { onMounted } from 'vue';

onMounted(async () => {
    handleScroll()
    floatingCore()
})


const handleScroll = (): void => {
    const floatingHeader = document.getElementById("floatingHeader");
    const mainHeader = document.getElementById("mainHeader");

    if (!floatingHeader || !mainHeader) {
        return;
    }

    const mainHeaderHeight = mainHeader.offsetHeight;
    let headerMoved = false;

    window.addEventListener("scroll", () => {
        if (!headerMoved && window.scrollY > mainHeaderHeight) {
            floatingHeader.style.transform = "translateY(16px)";
            headerMoved = true;
        } else if (window.scrollY <= (mainHeaderHeight + 16) && headerMoved) {
            floatingHeader.style.transform = "translateY(-100%)";
            headerMoved = false;
        }
    });
};


const floatingCore = (): void => {
    // Selecciona el ícono de flecha y el contenedor
    const arrow = document.querySelector<HTMLElement>('.mod-core__container--arrow');
    const modCore = document.querySelector<HTMLElement>('.mod-core');

    // Verifica si los elementos existen antes de agregar el evento
    if (arrow && modCore) {
        // Función para verificar el tamaño de la ventana
        const checkScreenSize=(): void => {
            if (window.innerWidth <= 767) { // Puedes ajustar el tamaño según tus necesidades
                modCore.classList.add('hide');
            } else {
                modCore.classList.remove('hide');
            }
        }

        // Inicializa la visibilidad al cargar la página
        checkScreenSize();

        // Añade un manejador de eventos para el clic
        arrow.addEventListener('click', () => {
            modCore.classList.toggle('hide');
        });

        // Vuelve a comprobar el tamaño de la ventana cuando se redimensiona
        window.addEventListener('resize', checkScreenSize);
    } else {
        console.warn('El ícono de flecha o el contenedor no se encontraron en el DOM.');
    }
}


</script>

