import Testimonials from "@/entities/Testimonials"
import { supabase } from "@/lib/supabaseClient"

export default class TestimonialsService {

    public async getTestimonials(): Promise<Testimonials[]> {
        const { data, error } = await supabase.from('testimonials').select().order('created_at', { ascending: false })
        if (error) throw error
        return data as Testimonials[]
    }

}