<template>
    <section class="pplugins">
        <div class="pplugins__container container">

            <div class="pplugins__title">
                <h2 class="pplugins__title--h2">Adicional a tu plan</h2>
                <p class="pplugins__title--p">Puedes contratar los siguientes <span>Plugins</span></p>
            </div>

            <div class="pplugins__plans">

                <div v-for="(plugin,index) in plugins" :key="index" class="pplugins__box">
                    <div class="pplugins__box--header">
                        <i :class="plugin.icon"></i>
                        <h3>{{ plugin.name }}</h3>
                    </div>
                    <div class="pplugins__box--body">
                        <h3 class="pplugins__box--price">{{ plugin.price }} UF</h3>
                        <p class="pplugins__box--period">mensuales</p>
                    </div>
                    <div class="pplugins__box--footer">
                        <a v-if="!plugin.soon" href="#" class="pplugins__box--button">
                            <span class="pplugins__box--span">
                                Contratar
                            </span>
                            <i class="ri-arrow-right-line"></i>
                        </a>

                        <a v-if="plugin.soon" href="#" class="pplugins__box--button" style="cursor: not-allowed;pointer-events: none;">
                            <span class="pplugins__box--span">
                                Proximamente
                            </span>
                            <i class="ri-history-line"></i>
                        </a>

                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import Plugins from '@/entities/Plugins';
import PluginsService from '@/services/PluginsService';
import { onMounted, ref, Ref } from 'vue';

const pluginsService: Ref<PluginsService> = ref(new PluginsService())
const plugins: Ref<Plugins[]> = ref([])

onMounted(async () => {
    await getPlugins()
})

const getPlugins = async () => {
    plugins.value = await pluginsService.value.getPlugins()
}
</script>