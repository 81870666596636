import Contacto from "@/entities/Contacto"
import { supabase } from "@/lib/supabaseClient"

export default class ContactService{

    public async addContact(contacto:Contacto): Promise<void> {
        const { data, error } = await supabase
        .from('contacto')
        .insert(contacto)
    }

}