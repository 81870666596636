<template>

    <section class="partners">

        <div class="partners__container container">

            <div class="partners__title">

                <h2 class="partners__title--h2">
                    Nuestros Partners
                </h2>

                <p class="partners__title--p">
                    los mejores aliados
                </p>

            </div>

            <div class="partners__content">

                <img class="partners__img partners__img--1" src="assets/img/partners/1.png" alt="">

                <div class="partners__info">

                    <p class="partners__info--p">
                        Gracias a estos aliados estratégicos, MDTech garantiza un rendimiento óptimo, alta disponibilidad, 
                        seguridad de datos y soluciones innovadoras que impulsan la eficiencia operativa y la 
                        satisfacción del cliente en cada etapa del proceso.
                    </p>

                    <p class="partners__info--autor">
                        Jhon Alexis Angel Godoi, CTO MDTech
                    </p>
                </div>

                <div class="partners__logo partners__logo--2">
                    <img class="partners__img partners__img--2" src="assets/img/partners/2.png" alt="">
                </div>

                <div class="partners__logo partners__logo--3">
                    <img class="partners__img partners__img--3" src="assets/img/partners/3.png" alt="">
                </div>

                <div class="partners__logo partners__logo--4">
                    <img class="partners__img partners__img--4" src="assets/img/partners/4.png" alt="">
                </div>

                <div class="partners__logo partners__logo--5">
                    <img class="partners__img partners__img--5" src="assets/img/partners/5.png" alt="">
                </div>

                <div class="partners__logo partners__logo--6">
                    <img class="partners__img partners__img--6" src="assets/img/partners/6.png" alt="">
                </div>

                <div class="partners__logo partners__logo--7">
                    <img class="partners__img partners__img--7" src="assets/img/partners/7.png" alt="">
                </div>

            </div>

        </div>

    </section>
</template>