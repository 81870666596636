<template>
  <TopBar></TopBar>
  <main class="main">
    <HeroSection />
    <ProcessSection />
    <ServicesSection />
    <PluginSection />
    <TestimonailsSection />
    <SpecializedSection />
    <PartnersSection />
    <SecuritySection />
  </main>
  <FooterBar />
</template>

<script lang="ts" setup>
import HeroSection from '@/components/home/HeroSection.vue';
import TopBar from '../components/TopBar.vue'
import ProcessSection from '@/components/home/ProcessSection.vue';
import ServicesSection from '@/components/home/ServicesSection.vue';
import PluginSection from '@/components/home/PluginSection.vue';
import TestimonailsSection from '@/components/home/TestimonailsSection.vue';
import SpecializedSection from '@/components/home/SpecializedSection.vue';
import SecuritySection from '@/components/home/SecuritySection.vue';
import PartnersSection from '@/components/home/PartnersSection.vue';
import FooterBar from '@/components/FooterBar.vue';

import { onMounted } from 'vue';

onMounted(async () => {
    handleScroll()
    floatingCore()
})

const handleScroll = (): void => {
    const floatingHeader = document.getElementById("floatingHeader");
    const mainHeader = document.getElementById("mainHeader");

    if (!floatingHeader || !mainHeader) {
        return;
    }

    const mainHeaderHeight = mainHeader.offsetHeight;
    let headerMoved = false;

    window.addEventListener("scroll", () => {
        if (!headerMoved && window.scrollY > mainHeaderHeight) {
            floatingHeader.style.transform = "translateY(16px)";
            headerMoved = true;
        } else if (window.scrollY <= (mainHeaderHeight + 16) && headerMoved) {
            floatingHeader.style.transform = "translateY(-100%)";
            headerMoved = false;
        }
    });
};

const floatingCore = (): void => {
    // Selecciona el ícono de flecha y el contenedor
    const arrow = document.querySelector<HTMLElement>('.mod-core__container--arrow');
    const modCore = document.querySelector<HTMLElement>('.mod-core');

    // Verifica si los elementos existen antes de agregar el evento
    if (arrow && modCore) {
        // Función para verificar el tamaño de la ventana
        const checkScreenSize=(): void => {
            if (window.innerWidth <= 767) { // Puedes ajustar el tamaño según tus necesidades
                modCore.classList.add('hide');
            } else {
                modCore.classList.remove('hide');
            }
        }

        // Inicializa la visibilidad al cargar la página
        checkScreenSize();

        // Añade un manejador de eventos para el clic
        arrow.addEventListener('click', () => {
            modCore.classList.toggle('hide');
        });

        // Vuelve a comprobar el tamaño de la ventana cuando se redimensiona
        window.addEventListener('resize', checkScreenSize);
    } else {
        console.warn('El ícono de flecha o el contenedor no se encontraron en el DOM.');
    }
}


</script>
