<template>
    <section class="process">

        <div class="process__container container">

            <div class="process__title">
                <h2 class="process__title--h2">Exalab te acompaña</h2>
                <p class="process__title--p">en todo tu proceso de <strong class="process__title--strong">exámenes
                        preocupacionales</strong></p>
            </div>

            <div class="process__diagram">

                <div class="process__diagram--content">

                    <a name="process-item-1" class="process__diagram--item">
                        <div class="process__diagram--circle">
                            <div class="process__diagram--number">1</div>
                            <div class="process__diagram--icon">
                                <img class="process__diagram--img" src="assets/icons/custom/solicitud-atencion-icon.svg"
                                    alt="">
                            </div>
                        </div>

                        <div class="process__diagram--title">
                            <h3 class="process__diagram--h3">Solicitud<br class="br-escritorio"> de atención</h3>
                        </div>
                    </a>

                    <a name="process-item-2" class="process__diagram--item">
                        <div class="process__diagram--circle">
                            <div class="process__diagram--number">2</div>
                            <div class="process__diagram--icon">
                                <i class="process__diagram--i ri-stethoscope-line"></i>
                            </div>
                        </div>

                        <div class="process__diagram--title">
                            <h3 class="process__diagram--h3">Atención<br class="br-escritorio"> de paciente</h3>
                        </div>
                    </a>

                    <a name="process-item-3" class="process__diagram--item">
                        <div class="process__diagram--circle">
                            <div class="process__diagram--number">3</div>
                            <div class="process__diagram--icon">
                                <i class="process__diagram--i ri-file-edit-line"></i>
                            </div>
                        </div>

                        <div class="process__diagram--title">
                            <h3 class="process__diagram--h3">Firma y emisión<br class="br-escritorio"> de informes</h3>
                        </div>
                    </a>

                    <a name="process-item-4" class="process__diagram--item">
                        <div class="process__diagram--circle">
                            <div class="process__diagram--number">4</div>
                            <div class="process__diagram--icon">
                                <i class="process__diagram--i ri-hospital-line"></i>
                            </div>
                        </div>

                        <div class="process__diagram--title">
                            <h3 class="process__diagram--h3">Operaciones</h3>
                        </div>
                    </a>

                    <a name="process-item-5" class="process__diagram--item">
                        <div class="process__diagram--circle">
                            <div class="process__diagram--number">5</div>
                            <div class="process__diagram--icon">
                                <i class="process__diagram--i ri-line-chart-line"></i>
                            </div>
                        </div>

                        <div class="process__diagram--title">
                            <h3 class="process__diagram--h3">Finanzas</h3>
                        </div>
                    </a>

                </div>

                <div class="process__diagram--line">
                    <div class="process__diagram--arrow"></div>
                    <div class="process__diagram--arrow"></div>
                    <div class="process__diagram--arrow"></div>
                    <div class="process__diagram--arrow"></div>
                </div>

                <!-- Bullets -->
                <ul class="process__diagram--bullets">
                    <li class="process__diagram--bullets-li">
                        <a href="#process-item-1" class="process__diagram--bullet"></a>
                    </li>

                    <li class="process__diagram--bullets-li">
                        <a href="#process-item-2" class="process__diagram--bullet"></a>
                    </li>

                    <li class="process__diagram--bullets-li">
                        <a href="#process-item-3" class="process__diagram--bullet"></a>
                    </li>

                    <li class="process__diagram--bullets-li">
                        <a href="#process-item-4" class="process__diagram--bullet"></a>
                    </li>
                    <li class="process__diagram--bullets-li">
                        <a href="#process-item-5" class="process__diagram--bullet"></a>
                    </li>
                </ul>

            </div>

            <div class="process__download">
                <a href="#" download class="process__download--a">
                    Ver proceso completo
                </a>
            </div>

        </div>

    </section>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';

onMounted(async () => {
    initializeProcessDiagram()
})


const initializeProcessDiagram=(): void => {
    document.addEventListener('DOMContentLoaded', () => {
        const content = document.querySelector<HTMLElement>('.process__diagram--content');
        const bullets = document.querySelectorAll<HTMLElement>('.process__diagram--bullet');
        const items = document.querySelectorAll<HTMLElement>('.process__diagram--item');
        let autoScrollInterval: number | undefined;

        if (content) {
            const updateActiveBullet = (): void => {
                const scrollLeft = content.scrollLeft;
                const scrollWidth = content.scrollWidth - content.clientWidth;
                const index = Math.round((scrollLeft / scrollWidth) * (bullets.length - 1));
                bullets.forEach((bullet, i) => {
                    bullet.classList.toggle('active', i === index);
                });
            };

            const scrollToItem = (index: number): void => {
                const targetItem = items[index];
                if (targetItem) {
                    const itemOffset = targetItem.offsetLeft - content.offsetLeft;
                    content.scrollTo({ left: itemOffset, behavior: 'smooth' });
                }
            };

            const startAutoScroll = (): void => {
                autoScrollInterval = window.setInterval(() => {
                    const scrollLeft = content.scrollLeft;
                    const scrollWidth = content.scrollWidth - content.clientWidth;
                    const currentIndex = Math.round((scrollLeft / scrollWidth) * (bullets.length - 1));
                    const nextIndex = (currentIndex + 1) % items.length;
                    scrollToItem(nextIndex);
                }, 3000);
            };

            const stopAutoScroll = (): void => {
                if (autoScrollInterval !== undefined) {
                    clearInterval(autoScrollInterval);
                }
            };

            bullets.forEach((bullet, index) => {
                bullet.addEventListener('click', (e: Event) => {
                    e.preventDefault();
                    stopAutoScroll();
                    scrollToItem(index);
                    startAutoScroll();
                });
            });

            items.forEach((item) => {
                item.addEventListener('mouseenter', stopAutoScroll);
                item.addEventListener('mouseleave', startAutoScroll);
            });

            content.addEventListener('scroll', updateActiveBullet);
            updateActiveBullet(); // Inicializa la clase active en el bullet correcto

            startAutoScroll(); // Inicia el scroll automático
        }
    });
}


</script>