<template>
     <section class="plans">

        <div class="plans__container container">

            <div class="plans__title">
                <h2 class="plans__title--h2">Precios Exalab</h2>
                <p class="plans__title--p">Elija el plan adecuado para usted</p>
            </div>

            <div class="plans__switch">
                <p class="plans__switch--p plans__switch--anual active">
                    Pago Anual
                </p>

                <div :class="['switch', 'switch__xl', pagoAnual?'':'b']" @click="switsh">
                    <div class="switch__button"></div>
                </div>

                <p class="plans__switch--p plans__switch--mensual">
                    Pago Mensual
                </p>

            </div>

            <div class="plans__options">

                <div class="plans__list">

                    <div v-for="(plan,index) in planes" :key="index" class="plans__box">

                        <div class="plans__box--header">
                            <h2 class="plans__box--h2">{{ plan.name }}</h2>
                            <p class="plans__box--p">Hasta <span>{{ plan.limit }}</span> informes mensuales</p>
                            
                            <div v-if="plan.most_seller" class="plans__box--featured">
                                <i class="plans__box--featuredicon ri-star-fill"></i>
                                <span class="plans__box--featuredtext">Más Comprado</span>
                            </div>

                        </div>

                        <div class="plans__box--body">
                            <h3 class="plans__box--price">{{ pagoAnual?(plan.price*((100-plan.discount)/100)).toFixed(1):plan.price }} UF</h3>
                            <p class="plans__box--period">mensuales</p>
                            <span class="plans__box--resume">Pago total anual <span>{{ pagoAnual?((plan.price*12)*((100-plan.discount)/100)).toFixed(1):plan.price*12 }} UF</span></span>
                            <span v-if="pagoAnual" class="plans__box--desc bg-gradient"><span>{{plan.discount}}%</span> de descuento</span>
                        </div>

                        <div class="plans__box--cta">
                            <a href="#" class="plans__box--button">
                                <span class="plans__box--span">
                                    Contratar
                                </span>
                                <i class="ri-arrow-right-line"></i>
                            </a>
                        </div>
                        
                    </div>

                </div>

            </div>

            <div class="plans__includes">

                <h3 class="plans__includes--h3">
                    Todos los Planes Incluyen
                </h3>

                <div class="plans__includes--list">

                    <div v-for="(modulo,index) in modulos" :key="index" class="plans__includes--item">
                        <div class="plans__includes--info">
                            <i :class="['plans__includes--icon', modulo.icon]"></i>
                            <div class="plans__includes--name">{{ modulo.short_name }}</div>
                        </div>
                        <i class="plans__includes--arrow ri-arrow-right-up-line"></i>
                    </div>

                </div>

            </div>

        </div>

    </section>
</template>


<script lang="ts" setup>
import Modulos from '@/entities/Modulos';
import Plans from '@/entities/Plans';
import ModulosService from '@/services/ModulosService';
import PlansService from '@/services/PlansService';
import { onMounted, Ref, ref } from 'vue';

const modulosService: Ref<ModulosService> = ref(new ModulosService())
const planService: Ref<PlansService> = ref(new PlansService())
const modulos: Ref<Modulos[]> = ref([])
const planes: Ref<Plans[]> = ref([])
const pagoAnual=ref(true)

onMounted(async () => {
    await getModulos()
    await getPlanes()
})

const getModulos = async () => {
    modulos.value = await modulosService.value.getModulos()
}

const getPlanes = async () => {
    planes.value = await planService.value.getPlans()
}

const switsh = ():void => {
   pagoAnual.value=!pagoAnual.value
}
</script>